.title {
  color: black;
  text-align: center;
  margin-bottom: 1rem;
}

.subtitle {  text-align: center;
  margin-bottom: 3rem;
}

.inputs_container {
  display: flex;
  flex-direction: column;
}

.inputs_container input {
  width: 95%;
  margin: 1.5rem auto;
  padding: 1rem;
  border-radius: 25px;
  font-size: 1rem;
  font-family: inherit;
  outline: 0;
  border: 0;
}

.login_button {
  display: block;
  margin: 3rem auto 1rem auto;
  width: 55%;
  padding: 0.75rem 1rem;
  border-radius: 25px;
  outline: 0;
  font-size: 1rem;
  font-family: inherit;
  cursor: pointer;
  background-color: #00BA60;
  color: white;
  border: 2px solid transparent;
  transition: 0.2s ease-in-out;
}

.login_button:hover {
  border: 2px solid #00BA60;
  background-color:white;
  color: black;
}

.link_container {
  display: flex;
  justify-content: center;
}

.small {
  color: rgb(218, 218, 218);
  text-align: center;
  font-size: 0.85rem;
  text-decoration: underline;
  margin-bottom: 5rem;
  cursor: pointer;
}

.icons {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.icon {
  color: white;
  cursor: pointer;
}

.error_msg {
  color: red;
  padding-left: 1rem;
  font-size: 0.85rem;
  margin: 0;
}

@media only screen and (max-width: 620px) {
  .inputs_container input {
    width: 100%;
  }

  .login_button {
    width: 100%;
  }

  .error_msg {
    padding-left: 0.2rem;
  }
}

@media only screen and (max-height: 670px) {
  .subtitle {
    margin-bottom: 2rem;
  }

  .small {
    margin-bottom: 3rem;
  }
}
.card {
  width: 550px;
  background-color: #f4f4f4;
  height: 500px;
  border-radius: 1rem;
  padding: 2rem;
  margin: 3rem auto;
}

@media only screen and (max-width: 620px) {
  .card {
    width: 90vw;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media only screen and (max-height: 670px) {
  .card {
    height: 650px;
  }
}
