/* .title {
  color: white;
  text-align: center;
  margin-bottom: 1rem;
}

.back_button {
  display: block;
  margin: 3rem auto 1rem auto;
  width: 55%;
  padding: 0.75rem 1rem;
  border-radius: 25px;
  outline: 0;
  font-size: 1rem;
  font-family: inherit;
  cursor: pointer;
  background-color: #000;
  color: white;
  border: 2px solid transparent;
}

.back_button:hover {
  border: 2px solid #000;
  color: #000;
  background-color: transparent;
}

.user-name{
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  font-size: 25px;
  font-weight: bold;
}
.user-dashboard{
  display: flex;
  justify-content: space-around;
}
.user-data p{
  font-size: 18px ;
}
.online-payment{
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  font-size: 18px;
}
.online-payment select{
  height: 3rem;
  font-size: 15px;
  border: 2px solid rgb(54, 225, 54);
}
.online-payment input{
  width: 30rem;
  height: 3rem;
  font-size: 18px;
  border: 2px solid rgb(54, 225, 54);
}
.online-payment .submit:hover{
  background-color: rgb(54, 225, 54);
}


@media (max-width: 850px) {
  .user-name {
    margin: 2rem 2rem;
  }
  .user-dashboard {
    flex-wrap: wrap;
  }
  .user-data{
      margin-right: 11rem;
  }
  .online-payment {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .user-name {
    font-size: 20px;
    margin: 1rem 1rem;
  }
  .user-dashboard {
    flex-direction: column;
    align-items: center;
  }
  .user-data{
      margin-right: 15rem;
  }
  .user-data p{
      font-size: 16px;
  }
  .online-payment {
    font-size: 14px;
  }
} */


.user {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 6rem;
  background-color: #f7f7f7;
}

.user-name {
  flex: 1 1 100%;
  margin-bottom: 5rem;
}

.user-name p {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
}

.user-dashboard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  flex: 2 1 100%;
  margin-left: 8rem; 
}

.user-data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-basis: 50%;
  margin-bottom: 1rem;
  text-align: start;
}

.user-tv-data,
.user-net-data {
  padding: 1rem;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
}

.user-tv-data h2,
.user-net-data h2 {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0 0 1rem;
}

.online-payment {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex-basis: 35%;
}

.online-payment h2 {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.online-payment label {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.online-payment select {
  margin-bottom: 1rem;
  padding: 0.5rem;
}

.online-payment input[type="text"] {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
}

.submit {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  background-color: #333;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
}

.back_button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  background-color: #333;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .user-dashboard {
  flex: 1 1 100%;
  margin: 0;
  }
  
  .user-data {
  flex-basis: 100%;
  }
  
  .online-payment {
  flex-basis: 100%;
  }
  }
@media screen and (max-width: 768px) {
  .user {
    flex-direction: column;
    padding: 2rem;
  }

  .user-name {
    margin-bottom: 3rem;
  }

  .user-dashboard {
    margin: 0;
  }

  .user-data {
    flex-basis: 100%;
  }

  .online-payment {
    flex-basis: 100%;
  }


}
